import React from 'react';
import { useLocation } from 'react-router';

import { SceneGridItemLike, SceneGridLayout, SceneGridRow, SceneTimeRangeState } from '@grafana/scenes';
import { GRID_CELL_HEIGHT, GRID_CELL_VMARGIN, GRID_COLUMN_COUNT } from 'app/core/constants';

import ReportFooter from './ReportFooter';
import ReportHeader from './ReportHeader';
import { calcGridItemPosition, PositionParams } from './utils';

const A4_WIDTH = 1122;

interface Props {
  grid: SceneGridLayout;
  title: string;
  timeRange: SceneTimeRangeState;
}

export function ReportGridRenderer({ grid, title, timeRange }: Props) {
  const { scaleFactor } = useUrlValues();

  uncollapseRows(grid.state.children);
  const { children } = grid.useState();
  const blocks = getReportPages(children, scaleFactor);

  const screenWidth = A4_WIDTH * scaleFactor - 2 * 16;

  return (
    <div style={{ position: 'relative' }}>
      {blocks.map((page, index) => (
        <>
          <ReportHeader title={title} timeRange={timeRange} scaleFactor={scaleFactor} />
          <div key={index} style={getPageStyle(page, screenWidth)}>
            {page.items.map((item, index) => (
              <div key={index} style={getItemStyle(item, screenWidth)}>
                {item.render()}
              </div>
            ))}
          </div>
          <ReportFooter scaleFactor={scaleFactor} />
          {page.pageBreakAfter && (
            <>
              <div style={{ pageBreakAfter: 'always', marginBottom: '16px' }} />
              <div style={{ marginBottom: '16px' }} />
            </>
          )}
        </>
      ))}
    </div>
  );
}

export function useUrlValues() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const scaleFactor = parseFloat(urlParams.get('scale') ?? '');

  return {
    scaleFactor: isNaN(scaleFactor) ? 1 : scaleFactor,
  };
}

interface ReportPage {
  items: ReportGridItem[];
  h: number;
  pageBreakAfter?: boolean;
}

interface ReportGridItem {
  x: number;
  y: number;
  w: number;
  h: number;
  render: () => React.ReactNode;
}

function uncollapseRows(children: SceneGridItemLike[]) {
  for (const gridChild of children) {
    if (gridChild instanceof SceneGridRow && gridChild.state.isCollapsed) {
      gridChild.onCollapseToggle();
    }
  }
}

function getReportPages(children: SceneGridItemLike[], scaleFactor: number): ReportPage[] {
  const pages: ReportPage[] = [];
  const maxRowCount = 17 * scaleFactor;
  let yShift = 0;
  let isAfterRow = false;

  let currentPage: ReportPage = {
    items: [],
    h: 0,
  };
  pages.push(currentPage);

  for (let i = 0; i < children.length; i++) {
    const gridChild = children[i];
    const isRow = gridChild instanceof SceneGridRow;
    const blockItem = getBlockItem(gridChild, yShift, maxRowCount, scaleFactor, isAfterRow);

    if (isRow) {
      // Get row children
      children.splice(i + 1, 0, ...gridChild.state.children);
    }

    const nextBlockItem =
      i < children.length - 1 ? getBlockItem(children[i + 1], yShift, maxRowCount, scaleFactor, isRow) : null;

    // Handle panel overflow (or next panel overflow if current panel is a row)
    let newH = Math.max(currentPage.h, blockItem.y + blockItem.h);
    let nextPanelH = 0;

    if (isRow && nextBlockItem != null) {
      nextPanelH = Math.max(currentPage.h, nextBlockItem.y - scaleFactor + 1 + nextBlockItem.h);
    }

    if (newH > maxRowCount || (isRow && nextPanelH > maxRowCount)) {
      currentPage.pageBreakAfter = true;
      yShift = gridChild.state.y! * scaleFactor;
      blockItem.y = 0;

      currentPage = {
        items: [],
        h: 0,
      };

      pages.push(currentPage);
    }

    currentPage.items.push(blockItem);
    currentPage.h = Math.max(currentPage.h, blockItem.y + blockItem.h);

    if (isRow) {
      isAfterRow = true;
      yShift += scaleFactor - 1;
    } else if (nextBlockItem != null && nextBlockItem.y !== blockItem.y) {
      isAfterRow = false;
    }
  }

  return pages;
}

function getBlockItem(
  gridChild: SceneGridItemLike,
  yShift: number,
  maxRowCount: number,
  scaleFactor: number,
  isAfterRow: boolean
): ReportGridItem {
  const blockItem: ReportGridItem = {
    x: gridChild.state.x!,
    y: gridChild.state.y! * scaleFactor - yShift,
    w: gridChild.state.width!,
    h: gridChild.state.height! * scaleFactor,
    render: () => <gridChild.Component model={gridChild} key={gridChild.state.key} />,
  };

  // If the panel is a row, its height should be 1, no matter the scale factor
  if (gridChild instanceof SceneGridRow) {
    blockItem.h = 1;
  }

  // If the panel is bigger than the maximum number of rows we can display in a page
  if (blockItem.h > maxRowCount) {
    if (isAfterRow) {
      blockItem.h = maxRowCount - 1;
    } else {
      blockItem.h = maxRowCount;
    }
  }

  return blockItem;
}

function getItemStyle(item: ReportGridItem, screenWidth: number): React.CSSProperties {
  const params: PositionParams = getGridParams(screenWidth);
  const position = calcGridItemPosition(params, item.x, item.y, item.w, item.h);

  return {
    top: position.top,
    left: position.left,
    width: position.width,
    height: position.height,
    position: 'absolute',
  };
}

function getPageStyle(block: ReportPage, screenWidth: number): React.CSSProperties {
  const params: PositionParams = getGridParams(screenWidth);
  const position = calcGridItemPosition(params, 0, 0, GRID_COLUMN_COUNT, block.h);

  return {
    width: position.width,
    height: position.height,
    position: 'relative',
  };
}

function getGridParams(screenWidth: number): PositionParams {
  return {
    margin: [GRID_CELL_VMARGIN, GRID_CELL_VMARGIN],
    containerWidth: screenWidth,
    containerPadding: [0, 0],
    cols: GRID_COLUMN_COUNT,
    rowHeight: GRID_CELL_HEIGHT,
    maxRows: 10000,
  };
}
