import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { DataSourceJsonData, DataSourceSettings } from '@grafana/data';
import { ConfigSection } from '@grafana/experimental';
import { getBackendSrv } from '@grafana/runtime';
import { Button, Stack } from '@grafana/ui';
import { contextSrv } from 'app/core/core';
import { DataSourceReadOnlyMessage } from 'app/features/datasources/components/DataSourceReadOnlyMessage';
import { Team } from 'app/types';

import { AccessControlAction as EnterpriseActions, EnterpriseStoreState, TeamRule } from '../types';

import { AddTeamLBACForm, LBACFormData } from './AddTeamLBACForm';
import { TeamRulesRow } from './TeamRulesRow';
import { getTeamLBAC, updateTeamLBACRules } from './state/actions';
import { formatLBACRule } from './utils';

export interface OwnProps {
  dataSourceConfig: DataSourceSettings<DataSourceJsonData, {}>;
  readOnly?: boolean;
  onTeamLBACUpdate: () => Promise<DataSourceSettings> | void;
}

function mapStateToProps(state: EnterpriseStoreState) {
  return {
    teamLBACConfig: state.teamLBAC?.teamLBACConfig,
  };
}

const mapDispatchToProps = {
  getTeamLBAC,
  updateTeamLBACRules,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = OwnProps & ConnectedProps<typeof connector>;

export const TeamLBACEditorUnconnected = ({
  teamLBACConfig,
  dataSourceConfig,
  readOnly,
  onTeamLBACUpdate,
  getTeamLBAC,
  updateTeamLBACRules,
}: Props) => {
  const [teams, setTeams] = useState<Array<Pick<Team, 'name' | 'avatarUrl' | 'id'>>>([]);
  const [showLBACForm, setShowLBACForm] = useState(false);
  const lbacRules = useMemo(() => teamLBACConfig?.rules || [], [teamLBACConfig?.rules]);

  useEffect(() => {
    getTeamLBAC(dataSourceConfig.uid);
  }, [dataSourceConfig.uid, getTeamLBAC]);

  useEffect(() => {
    const fetchTeams = async () => {
      const teamIds = lbacRules.map((r) => r.teamId);
      if (!teamIds?.length) {
        return;
      }

      const result = await getBackendSrv().get('/api/teams/search', { teamId: teamIds });
      const teamsArray: Team[] = result?.teams;
      const teams = teamsArray.map((team) => ({
        id: team.id,
        value: team.id,
        teamId: team.id,
        name: team.name,
        avatarUrl: team.avatarUrl,
      }));
      setTeams(teams);
    };
    fetchTeams();
  }, [lbacRules]);

  const onTeamLBACUpdateInternal = useCallback(
    async (rules: TeamRule[]) => {
      await updateTeamLBACRules(dataSourceConfig.uid, { rules });
      await onTeamLBACUpdate();
    },
    [dataSourceConfig.uid, onTeamLBACUpdate, updateTeamLBACRules]
  );

  const onSubmitLBAC = async ({ team, rule }: LBACFormData) => {
    let updatedRules: TeamRule[] = [];
    const existingTeamRules = lbacRules.find((teamRules) => teamRules.teamId === team.toString());
    if (existingTeamRules) {
      updatedRules = lbacRules.map((teamRules) => {
        if (teamRules.teamId === team.toString()) {
          return { ...teamRules, rules: [...teamRules.rules, formatLBACRule(rule)] };
        }
        return { ...teamRules };
      });
    } else {
      updatedRules = lbacRules.concat({ teamId: team.toString(), rules: [formatLBACRule(rule)] });
    }
    await onTeamLBACUpdateInternal(updatedRules);
    setShowLBACForm(false);
  };

  const onRulesUpdate = async (teamId: string, teamRules: string[]) => {
    const updatedRules: TeamRule[] = [];
    lbacRules.forEach((r) => {
      if (r.teamId === teamId) {
        if (!teamRules?.length) {
          return;
        }
        updatedRules.push({ ...r, rules: teamRules });
      } else {
        updatedRules.push({ ...r });
      }
    });
    await onTeamLBACUpdateInternal(updatedRules);
  };

  const canEdit = contextSrv.hasPermission(EnterpriseActions.DataSourcesPermissionsWrite) && !readOnly;

  return (
    <div>
      {readOnly && <DataSourceReadOnlyMessage />}
      <Stack direction="column" gap={2}>
        <ConfigSection title="Team LBAC" description="Configure access to data based on team membership.">
          <Stack direction="column" alignItems={'start'} gap={2}>
            {canEdit && <Button onClick={() => setShowLBACForm(true)}>Add LBAC rule</Button>}
            {showLBACForm && <AddTeamLBACForm onSubmit={onSubmitLBAC} onClose={() => setShowLBACForm(false)} />}
          </Stack>
        </ConfigSection>
        {!isEmpty(lbacRules) && (
          <table role="grid" className="filter-table gf-form-group" aria-labelledby="team_lbac_rules">
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Team</th>
                <th style={{ width: '1%' }} />
                <th>Label selector</th>
                <th style={{ width: '1%' }} />
              </tr>
            </thead>
            <tbody>
              {lbacRules.map((teamRules, idx) => {
                const rules = teamRules.rules;
                const teamId = teamRules.teamId;
                return (
                  <TeamRulesRow
                    key={idx}
                    teamRules={rules}
                    disabled={!canEdit}
                    team={
                      teams.find((t) => t.id?.toString() === teamId) || {
                        id: Number(teamId),
                        name: '',
                        avatarUrl: '',
                      }
                    }
                    onChange={(teamRules) => onRulesUpdate(teamId, teamRules)}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </Stack>
    </div>
  );
};

export const TeamLBACEditor = connector(TeamLBACEditorUnconnected);
